import * as Yup from "yup";

const lettersRegex = /^[A-Za-z\s{2,}]+$/;
const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/gi;
const passwordRegex =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;
const numberRegex = /^[0-9]+$/;
const letterNumberRegex = /^[a-zA-Z0-9]+$/;

const email = Yup.string()
    .required("Email Address is required")
    .email("Email Address is invalid")
    .matches(emailRegex, "Email Address is invalid"),
  firstName = Yup.string()
    .required("First Name is required")
    .min(3, "First Name must be at least 3 characters")
    .max(30, "First Name must be less than 30 characters")
    .matches(lettersRegex, "cannot contain numbers and special characters"),
  lastName = Yup.string()
    .required("Last Name is required")
    .min(3, "Last Name must be at least 3 characters")
    .max(30, "Last Name must be less than 30 characters")
    .matches(lettersRegex, "cannot contain numbers and special characters"),
  password = Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(16, "Password must be less than 16 characters"),
  phoneNumber = Yup.string()
    .required("Phone Number is required")
    .min(13, "Phone Number cannot be less than 13 characters")
    .max(13, "Phone Number cannot be more than 13 characters");

export const vCreateUser = Yup.object({
  firstName,
  middleName: Yup.string(),
  lastName,
  staffId: Yup.string().required("Staff ID is required"),
  bvn: Yup.string()
    .required("BVN is required")
    .min(11, "BVN cannot be less than 11 digits")
    .max(11, "BVN cannot be more than 11 digits")
    .matches(numberRegex, "Invalid BVN number"),
  department: Yup.string().required("Department is required"),
  branch: Yup.string().required("Branch is required"),
  phoneNumber,
  email,
});

export const userLogin = Yup.object({
  email,
  password,
});

export const vCreateMda = Yup.object({
  department: Yup.string()
    .required("Department Name is required")
    .min(2, "Department Name must be more than 2 characters"),
});
export const vCreateEmail = Yup.object({
  email,
});

export const vCreateBranch = Yup.object({
  branchName: Yup.string()
    .required("Branch Name is required")
    .min(2, "Branch Name must be more than 2 characters"),
  branchAddress: Yup.string()
    .required("Branch Address is required")
    .min(3, "Branch Address must be more than 3 characters"),
  state: Yup.string().required("State is required"),
});

export const vCreateDevice = Yup.object({
  deviceName: Yup.string()
    .required("Device Name is required")
    .min(2, "Device Name must be more than 2 characters"),
  deviceImei: Yup.string()
    .required("IMEI is required")
    .min(15, "IMEI cannot be less than 15 characters")
    .max(15, "IMEI cannot be more than 15 characters")
    .matches(numberRegex, "Invalid IMEI"),
  deviceSerialNo: Yup.string()
    .required("Serial Number is required")
    .matches(letterNumberRegex, "Invalid Serial number"),
  branch: Yup.string().required("Branch is required"),
});

export const userForgotPassword = Yup.object({
  email,
});

export const userResetPassword = Yup.object({
  newPassword: password.matches(
    passwordRegex,
    "Password must contain at least 1 of the following characters; uppercase, lowercase, numbers and special characters"
  ),
  confirmPassword: password.matches(
    passwordRegex,
    "Password must contain at least 1 of the following characters; uppercase, lowercase, numbers and special characters"
  ),
});
