/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import config from "./config";
import EventNoteIcon from '@mui/icons-material/EventNote';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';

const {
  routes
} = config;

const links = [{
    authorizedUsers: ['all'],
    name: "Dashboard",
    url: routes.dashboard,
    icon: <DashboardIcon width={"22px"}
    className="mx-3" /> 
    
  },
  {
    authorizedUsers: ['all'],
    name: "Users",
    url: routes.user,
    icon: <GroupIcon width={"22px"}
    className="mx-3" /> 
    
  },

  {
    authorizedUsers: ['all'],
    name: "Report",
    url: routes.report,
    icon: <EventNoteIcon width={"22px"}
    className="mx-3" /> 
  },
  {
    authorizedUsers: ['all'],
    name: "Settings",
    url: routes.settings,
    icon: <SettingsIcon width={"22px"}
    className="mx-3" /> 
  },
]

export default links;