import Swal from 'sweetalert2'
import { capitalize } from './helper'
 
 
 const Alert = function({type = "success", message, timer, cb}) {
  const msg = capitalize(message?.split(' ')?.[0]) +' '+ message?.split(' ')?.filter?.((txt, i)=> i>0)?.join(' ')
  function config (timer = 4000, {type = "success", message, cb}) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
     })
    
     Toast.fire({
      icon: type,
      title: message,
      didDestroy: cb
    })
    
     return Toast
    }
   return config(timer, {type, message: msg, cb})
 }

 export default Alert;