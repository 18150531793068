import React from 'react';
import { CircularProgress, FormControl, InputAdornment, ListSubheader, MenuItem, Select, TextField, Typography } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";

const SelectInput = ({name, label, value, handleChange, handleBlur, handleFocus, error, options, helperText, bg = 'white', required, ...other}) => {
  const { className, handleSearch, searchLoading, readOnly = false, loading, } = other
 return (
  <FormControl hiddenLabel required className="w-full" {...{ error, className }}>
        <Select
        MenuProps={{height:'200px'}}
        
          labelId={name}
          id={name}
          value={value}
										name={name}
										onChange={handleChange}
										onBlur={handleBlur}
										onFocus={handleFocus}
                    required={required}
                    readOnly={readOnly}
          sx={{background: bg, textTransform: 'capitalize',}}
          endAdornment={loading&&(
            <div>
                    <CircularProgress sx={{background: 'white'}} color="inherit" size={16} /> 
                  </div>)
          }
        >

          {handleSearch && <ListSubheader>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Type to search..."
              fullWidth
              style={{textTransform: 'capitalize'}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: searchLoading && (
                  <React.Fragment>
                    <CircularProgress color="inherit" size={20} />
                  </React.Fragment>
                ),
              }}
              onChange={handleSearch}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>}

          {
            
          }

{loading ? 
          <p
              style={{fontWeight: 500, padding: '0 1em', width: '100%'}}
            >
              Loading...
            </p> : options?.map?.((data, key) => {
              if(data instanceof Object) return <MenuItem {...{style: {textTransform: 'capitalize'}, value: data, key}}> {data.value || 'No record found'} </MenuItem> 
              else return <MenuItem {...{style: {textTransform: 'capitalize'}, value: data, key}}> {data || 'No record found' } </MenuItem>
            })
          }
        </Select>
        {error && <Typography className="bg-white pt-1 text-[0.75rem] px-[14px] leading-[1.66] font-normal" color='error'>{helperText || ""}</Typography>}
					</FormControl>
 );
}

export default SelectInput;
