import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";

export const getDepartments = createAsyncThunk('/settings/department', ({search, pageSize} = {}, thunkApi) => {
 try {
  const { pagination } = thunkApi.getState().departments
  return new BACKEND().send({
   type: 'get',
   to: `/department/departments/?pageNumber=${pagination.page}&pageSize=${pageSize || pagination.pageSize}&search=${search || pagination.search || pagination.filter || ""}`,
   useAlert: false
 })
 } catch (error) {
   console.error(error);
 }
})


export const createDepartment = createAsyncThunk('/settings/department/create', ({departmentId, ...payload}, thunkApi) => {
  try {
   return new BACKEND().send({
    type: 'post',
    to: "/department/create",
    useAlert: true,
    payload
  })
  } catch (error) {
    console.error(error);
  }
 })

 export const updateDepartment = createAsyncThunk('/settings/department/update', ({departmentId, department}, thunkApi) => {
  try {
   return new BACKEND().send({
    type: 'patch',
    to: `/department/department/${departmentId}`,
    useAlert: true,
    payload: { department }
  })
  } catch (error) {
    console.error(error);
  }
 })
 
 export const deleteDepartment = createAsyncThunk('/settings/department/delete', (departmentId, thunkApi) => {
  try {
   return new BACKEND().send({
    type: 'delete',
    to: `/department/department/${departmentId}`,
    useAlert: true,
  })
  } catch (error) {
    console.error(error);
  }
 })

const initialState = {
 loading: false,
 modalLoading: false,
 departments: [],
 payload: {
  "department": "",
  "createdBy": ""
},
 pagination: {
  page: 1,
  pageSize: 10,
  total: 0,
  length: 0,
  search: '',

 }
};

export const departmentSlice = createSlice({
 name: 'departments',
 initialState,
 reducers: {
  setPagination: (state, { payload }) => {
   state.pagination = {...state.pagination, ...payload}
  }
 },
 extraReducers: (builder => {
  builder.addCase(getDepartments.pending, (state) => {
   state.loading = true;
  })
  .addCase(getDepartments.fulfilled, (state, { payload }) => {
   state.loading = false;
   if(payload.success) {
    state.departments = payload?.data?.departments
    state.pagination.total = payload.data.count;
    state.pagination.length = state.pagination.pageSize * state.pagination.page
   }
  })
  .addCase(getDepartments.rejected, state => {state.loading = false})
  
  builder.addCase(deleteDepartment.pending, (state) => {
   state.modalLoading = true;
  })
  .addCase(deleteDepartment.fulfilled, (state, { payload }) => {
   state.modalLoading = false;
  })
  .addCase(deleteDepartment.rejected, state => {state.modalLoading = false})
  
  builder.addCase(createDepartment.pending, (state) => {
   state.loading = true;
  })
  .addCase(createDepartment.fulfilled, (state, { payload }) => {
   state.loading = false;
  })
  .addCase(createDepartment.rejected, state => {state.loading = false})
  
  builder.addCase(updateDepartment.pending, (state) => {
   state.loading = true;
  })
  .addCase(updateDepartment.fulfilled, (state, { payload }) => {
   state.loading = false;
  })
  .addCase(updateDepartment.rejected, state => {
    state.loading = false 
  })
  
 })
})

export const { setPagination } = departmentSlice.actions
export const getDepartmentData = state => state.departments
export default departmentSlice.reducer;