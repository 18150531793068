import { React } from 'react';
import config from "../utils/config";
import Dashboard from "./Dashboard";
import Report from "./Report";
import Create from "./settings/Create";
import Settings from "./settings/Settings";
import UploadUser from "./user/UploadUser";
import Users from "./user/User";
import CreateUser from "./user/createUser";
const { routes } = config;

const router = [
 {
  element: <Users />,
  path: routes.user
 },
 {
  element: <CreateUser />,
  path: routes.createUser
 },
 {
  element: <CreateUser />,
  path: routes.createUser+'/:id'
 },
 {
  element: <UploadUser />,
  path: routes.uploadUsers
 },
 {
  element: <Report />,
  path: routes.report
 },
 {
  element: <Dashboard />,
  path: routes.dashboard 
 },
 {
  element: <Settings />,
  path: routes.settings
 },
 {
  element: <Create />,
  path: routes.createSettings
 },
 {
  element: <Create />,
  path: routes.createSettings+'/:id'
 },
]
export default router;