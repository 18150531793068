const environment = {};

const routes = {
  home: "/",
  dashboard: "/dashboard",
  user: "/users",
  uploadUsers: "/users/upload",
  createUser: "/users/create",
  report: "/report",
  settings: "/settings",
  createSettings: "/settings/create",
};

environment.development = {
  authProps: ["attendance/token", "attendance/user"],
  backendUrl:
    process.env.REACT_APP_BACKEND_URL ||
    "http://ubaattendanceapi.timeguard.ng/",
  routes,
};

environment.staging = {
  authProps: ["attendance/token", "attendance/user"],
  backendUrl:
    process.env.REACT_APP_BACKEND_URL ||
    "http://ubaattendanceapi.timeguard.ng/",
  routes,
};

environment.production = {
  authProps: ["attendance/token", "attendance/user"],
  backendUrl:
    process.env.REACT_APP_BACKEND_URL ||
    "http://ubaattendanceapi.timeguard.ng/",
  routes,
};
console.log(
  process.env.REACT_APP_ENV || "development",
  process.env.REACT_APP_BACKEND_URL
);
export default environment[process.env.REACT_APP_ENV || "development"];
