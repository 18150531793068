 /* eslint-disable react-hooks/exhaustive-deps */
 import React, { useRef, useState } from "react";
 import Box from "@mui/material/Box";
 import {
  Typography,
  Button,
 } from "@mui/material";
 import { LoadingButton } from "@mui/lab";
 import { useDispatch, useSelector } from "react-redux";
 import {  getUserData, uploadUsers } from "../../features/users/userSlice";
 import HomeLayout from "../../layouts/Home";
 import { useNavigate } from "react-router-dom";
 import CloudUploadIcon from '@mui/icons-material/CloudUpload';
 import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { IExcel } from "../../utils/icons.utils";
import { getFileSize } from "../../utils/helper";
import Alert from "../../utils/alert";
 
 const UploadUser = () => {
   const dispatch = useDispatch(),
   navigate = useNavigate(); 
  const { loading} = useSelector(getUserData);
  const fileUploadDiv = useRef(null);
	const [image, setImage] = useState({ size: 0, type: "", name: "", files: [] });

 const dragEnter = (_) => {
  fileUploadDiv.current.classList.add("cursor-move");
 },
 formData = new FormData(),
 dragDrop = (e) => {
  e.preventDefault();
  fileUploadDiv.current.classList.remove("drag_active");
  const files = e?.dataTransfer?.files || e?.target?.files;

  if (files.length > 0) {
   const file = files[0],
   fileArr = file.name.split('.');

   if(fileArr[fileArr.length-1] === 'xlsx'){ 
  
   setImage((state) => {
    return {
     ...state,
     files,
     size: getFileSize({ size: file.size }),
     type: file.type,
     name: file.name,
    }
   });
  } else Alert({type: 'error', message: "Only excel file is allowed"})
  }
 };
 
 const handleUpload = async e => {
  formData.append('file', image.files[0]);
    const res = await dispatch(uploadUsers({payload: formData, header: {contentType: 'multipart/form-data'}})).unwrap();
    res.success && navigate(-1);
}

  return (
   <HomeLayout {...{showNavigation: true}}>
        <Typography
      variant="h6"
      noWrap
      component="div"
      color="#040316"
      fontSize="32px"
      className="sofiaProBold"
      sx={{ my: 3 }}>
      Users
     </Typography>
 
 <div  
 className="pb-10"
     style={{ borderTopLeftRadius: "100" }}>
     <Box sx={{bgcolor: "var(--c-primary-2)", p: "1em"}} className='rounded-md'>
     <Box
     ref={fileUploadDiv}
      className="text-center rounded-lg py-20 lg:px-[4em] relative px-[1em]"
      sx={{
      }}>
       <input 
       	onDragEnter={dragEnter}
        onDrop={dragDrop}
        onChange={dragDrop}
       type="file" className="opacity-0 absolute left-0 top-0 bottom-0 right-0" id="file"/>
       {!image.name ? <>
       
       <Typography className="text-2xl">Drag excel file here</Typography>
       <Typography className="text-2xl"> Or</Typography> 
       <Typography className="text-2xl">select file</Typography>
      
      <Box className="mx-auto">
      <Button
       startIcon={<CloudDownloadIcon />}
       component='a'
       download
       href="/uba_attendance.xlsx"
        className="my-10 w-[240px]  rounded-[8px] bg-[var(--c-primary-0)] hover:bg-[#FF5C5C]  self-end shadow-none normal-case"
        sx={{
         ".MuiLoadingButton-loadingIndicatorCenter": {
          color: "var(--c-bg-color) !important",
         },
        }}
        size="large"
        type="submit"
        variant="contained"
        fullWidth>
         Download Template
       </Button ><br />

       <Button
       component='label'
       htmlFor="file"
       startIcon={<CloudUploadIcon />}
        className="w-[240px] rounded-[10px] bg-[var(--c-primary-1)] hover:bg-[var(--c-primary-1)] text-[var(--c-primary-0)] self-end shadow-none normal-case"
        sx={{".MuiLoadingButton-loadingIndicatorCenter": { color: "white" } }}
        size="large"
        variant="contained"
        fullWidth>
        Upload Excel File
       </Button> 

       
       
      </Box>
        </>
      :
      <div className="mx-auto  w-[300px]">
       <Typography className="text-lg">{image.name}</Typography>
       <img src={IExcel} className="w-[100px] mx-auto" alt="excel" />
       <Typography className="text-xl">{image.size}</Typography>

       <LoadingButton
       onClick={handleUpload}
       startIcon={<CloudUploadIcon />}
        className="my-10 w-[240px]  rounded-[8px] bg-[var(--c-primary-0)] hover:bg-[#FF5C5C]  self-end shadow-none normal-case"
        sx={{
         ".MuiLoadingButton-loadingIndicatorCenter": {
          color: "var(--c-bg-color) !important",
         },
        }}
        size="large"
        type="submit"
        variant="contained"
        disabled={loading}
        loading={loading}
        fullWidth>
         Upload file
       </LoadingButton>
      </div>
}
     </Box>
     </Box>
    </div>
   </HomeLayout>
  );
 };
 
 export default UploadUser;
 