import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth/authSlice";
import users from "./users/userSlice";
import departments from "./settings/departmentSlice";
import branches from "./settings/branchSlice";
import devices from "./settings/deviceSlice";
import dashboard from "./dashboard/dashboardSlice";
import system from "./settings/system";
import emails from "./settings/EmailSlice";

export const store = configureStore({
  reducer: {
    auth,
    users,
    departments,
    branches,
    devices,
    dashboard,
    system,
    emails,
  },
});
