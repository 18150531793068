import Storage from "./storage";
import config from "./config";

/**
 * @param {object} data   takes in an object of boolean and number values
 * @param {boolean} data.previewInConsole  whether to preview the data/size in the console, default is true
 * @param {number} data.size  the actual size of the data/file in byte, default is 50000000
 * @returns  {number}  The size of the data/file
 **/
export const getFileSize = function (data = {}) {
  data.previewInConsole = data.previewInConsole ? data.previewInConsole : false;
  data.size = data.size !== (undefined || null || "") ? data.size : 50000000; // 50mb
  data.size = Number(data.size);
  const k = 1000;
  const format = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(data.size) / Math.log(k));
  const size = parseFloat(data.size / Math.pow(k, i)).toFixed(1);

  if (data.previewInConsole === true)
    console.info(data.size, " = ", size + format[i]);
  return size + " " + format[i];
};

export const getAmount = (num) => new Intl.NumberFormat().format(num);

export function currencyFormat(num, currencySymbol) {
  return (
    currencySymbol + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
}

export const Roles = {
  admin: "admin",
  superAdmin: "superadmin",
};

export const loggedInUser = Storage.get(config.authProps[1]);
export const getRole = () => {
  let user = {};
  if (loggedInUser) {
    user = JSON.parse(loggedInUser);
  }
  return user.role[0];
};
export const getIds = () => {
  let user = {};
  if (loggedInUser) {
    user = JSON.parse(loggedInUser);
  }
  return {
    company: user.companies[0].id,
    hmo: user.hmos[0].id,
    hospital: user.hospitals[0].id,
    user: user.id,
  };
};

export const validateEmail = (email) => {
  const regexExp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
  return regexExp.test(email);
};

export const capitalize = (string) => {
  const final = string.replace(/\w\S*/g, (txt) => {
    let val = txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    return val;
  });
  return final;
};

export const tabList = [
  {
    name: "Departments",
    id: 1,
    code: "Department",
  },
  {
    name: "Branches",
    id: 2,
    code: "Branch",
  },
  {
    name: "Devices",
    id: 3,
    code: "Device",
  },
  {
    name: "Emails",
    id: 4,
    code: "Email",
  },
  {
    name: "System",
    id: 5,
    code: "System",
  },
];

export const getBranchIdByName = (name, branches) =>
  branches.find(
    (branch) => branch?.branchName?.toLowerCase() === name?.toLowerCase()
  )?.id;
export const getMdaIdByName = (name, departments) =>
  departments.find(
    (branch) => branch?.department?.toLowerCase() === name?.toLowerCase()
  )?.id;
