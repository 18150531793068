/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import HomeLayout from "../../layouts/Home";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Table from "../../components/table/Table";
import TablePreloader from "../../components/Loader/TablePreloader";
import SearchBar from "../../components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import {
	getUserData,
	getUsers,
	setPagination,
	setUser,
} from "../../features/users/userSlice";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import config from "../../utils/config";
import Menu from "../../utils/data.dropdown";
import ViewModal from "../../components/Modals/View";
const { routes } = config;

export default function Users() {
	const { loading, users, user, pagination } = useSelector(getUserData),
		dispatch = useDispatch(),
		[open, setOpen] = useState(false),
		navigate = useNavigate();

	useEffect(() => {
		dispatch(setPagination({ page: 1, search: '',  }));
	}, []);

	useEffect(() => {
		let cb = () => {};
		if (pagination.search) {
			dispatch(setPagination({ page: 1 }));
			cb = setTimeout((_) => (async () => await dispatch(getUsers()))(), 700);
		} else cb = (async () => await dispatch(getUsers()))();

		return () => {
			clearTimeout(cb);
		};
	}, [pagination.search, pagination.page, pagination.filter]);

	const handleSearch = ({ target: { value } }) => {
		dispatch(setPagination({ ...pagination, search: value }));
	};

	const menu = new Menu({});
	const dropdownMenu = [
		menu.view({action: data => {
			dispatch(setUser(data))
			setOpen(true)
		}, name: ''}),
		menu.edit({ navigate, route: routes.createUser }),
	]

	const data = users.map((user, idx) => ({
			"S/N": pagination.pageSize * (pagination.page - 1) + idx + 1,
			name: user.firstName + " " + user.lastName,
			"Department": user?.Department?.department || "N/A",
			"Staff ID": user.staffId || "N/A",
			"BVN": user.bvn || 'N/A',
			"Branch": user?.Branch?.branchName || "N/A",
			"Date Created": dayjs(user.createdAt).format("MMM DD, YYYY"),
			Status: user.verified ? "Verified" : "Unverified",
			_data: user
	}));


	return (
		<HomeLayout>
			<div className="mb-10">
				<Typography
					variant="h6"
					noWrap
					component="div"
					color="#040316"
					fontSize="32px"
					className="sofiaProBold"
					sx={{ my: 3 }}>
					Users
				</Typography>

				<div className="lg:grid lg:grid-cols-3 mb-8">
					<div className="col-span-2 lg:mb-0 mb-6">
						<SearchBar {...{ onChange: handleSearch, placeholder: 'Search by name or staff id...'}}/>
					</div>
					<div className="grid grid-cols-2 gap-8 items-center col-span-1">
     <Button disabled={loading} component={Link} to={config.routes.uploadUsers} sx={{textTransform: 'capitalize', borderRadius: '.5em', p: '0px'}} variant="text" size="small" className="h-[42px] font-[500] md:text-[15px] text-[10px] rounded-[10px] bg-[var(--c-primary-1)] py-0 text-[#D70900] hover:bg-[var(--c-danger-3)] hover:text-white"> Upload Users</Button>

						<Button disabled={loading} component={Link} to={config.routes.createUser} sx={{textTransform: 'capitalize', borderRadius: '.5em', p: '0px'}} variant="text" size="small" className="h-[42px] font-[500] md:text-[15px] text-[10px] rounded-[10px] text-white py-0 bg-[#D70900] hover:bg-[#FF5C5C]"> Create New User</Button>
					</div>
				</div>

				<Box className="">
				<div className="w-full">
					<Container
						className={`${(data.length > 0 || loading) && "mt-8 py-5 bg-[var(--c-bg-color)]"} rounded-lg lg:min-w-full`}
						sx={{ px: 0 }}>
						{data.length > 0 && <Box className="flex justify-between mb-2">
								<Typography fontFamily={"Lota"}>All Users</Typography>
							</Box>}

							{loading && users.length === 0 ? (
								<TablePreloader />
							) :
								<Table
									{...{
										data,
										dropdownMenu,
										setPagination: (d) => dispatch(setPagination(d)),
										pagination: { ...pagination, hidden: false },
										isLoading: loading,
										tableMsg: ['No User created,', 'Kindly check back later.']
									}}
								/>
							}
						</Container>
					</div>
				</Box>
			</div>

			<ViewModal
			{
				...{
					open,
					handleClose: _=> setOpen(false),
					title: 'View User',
					data:	user
				}
			}
			/>
		</HomeLayout>
	);
}
