/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import DatePickers from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IDatePicker } from "../../utils/icons.utils";
import dayjs from "dayjs";

const DatePicker = ({ handleDateFilter }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const handleStartDateClick = () => {
    startDateRef.current.setFocus(true);
  };

  const handleEndDateClick = () => {
    endDateRef.current.setFocus(true);
  };

  useEffect(() => {
    handleDateFilter({
      startDate: startDate ? dayjs(startDate).toISOString() : "",
      endDate: endDate ? dayjs(endDate).toISOString() : "",
    });
  }, [startDate, endDate]);

  return (
    <div className="border-2 border-[#F8F7F7] flex justify-end date-range-picker md:w-[67%] bg-white py-1 pl-1 rounded-md">
      <div className="date-input flex text-dark_red text-sm font-semibold ml-4 w-full">
        <img
          src={IDatePicker}
          alt="IDatePicker"
          className="mr-1 cursor-pointer h-[1.3rem]"
          onClick={handleStartDateClick}
        />
        <DatePickers
          ref={startDateRef}
          maxDate={endDate}
          className="w-full px-1 pt-1 bg-transparent"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          placeholderText="Start Date"
          wrapperClassName="date-picker-wrapper"
          dateFormat="MM/dd/yyy"
        />
      </div>
      <span className="w-[15px]  mt-1 pl-">-</span>

      <div className="date-input flex w-full text-dark_red text-sm font-semibold ml-7 ">
        <img
          src={IDatePicker}
          alt="IDatePicker"
          className="mr-1 cursor-pointer h-[1.3rem]"
          onClick={handleEndDateClick}
        />
        <DatePickers
          ref={endDateRef}
          minDate={startDate}
          className="w-full px-1 pt-1 bg-transparent"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          placeholderText="End Date"
          wrapperClassName="date-picker-wrapper"
          dateFormat="MM/dd/yyy"
        />
      </div>
    </div>
  );
};

export default DatePicker;
