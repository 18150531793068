import Alert from "./alert";
import config from "./config";
import Storage from "./storage";

class API_INSTANCE {
  constructor({ timeout = 1000000, url, token } = {}) {
    this.timeout = timeout;
    this.url = url;
    this.token = token;
  }

  create(instance) {
    const API = instance;

    API.defaults.baseURL = this.url;
    API.defaults.timeout = this.timeout;

    API.defaults.headers.common["Authorization"] = `Bearer ${
      this.token || Storage.get(config.authProps[0])
    }`;
    API.defaults.headers.common["ngrok-skip-browser-warning"] = true;

    const handleSessionExpired = (error) => {
      const msg = error.response.data?.message,
        message = msg instanceof Array ? msg[0] : msg;
      const isNotAuth = [
        "authorization",
        "jwt",
        "authorized",
        "Forbidden...",
        "expired token",
        "expired token.",
        "token",
        "Forbidden...You are using an expired token",
        "Forbidden...Authorization headers were not set",
        "invalid signature",
      ]?.find((msg) => {
        const result =
          error?.response?.data?.error
            ?.toLowerCase()
            .includes(msg?.toLowerCase()) ||
          message?.toLowerCase().includes(msg?.toLowerCase());
        return result;
      });

      const cb = () => {
        setTimeout((_) => (window.location.href = config.routes.home), 2000);
      };
      // messages = "Session expired, please login again.";

      if ([403].includes(error?.response?.status) && isNotAuth) {
        Storage.remove(config.authProps[0]);
        Storage.remove(config.authProps[1]);
        Alert({
          type: "error",
          message: "Session expired, please login again.",
          cb,
        });
        return;
      } else {
        return Promise.reject(error.response.data);
      }
    };

    const handleSuccess = (response) => {
      return response;
    };

    API.interceptors.response.use(handleSuccess, handleSessionExpired);

    API.interceptors.request.use(
      (configs) => {
        // const cb = () => setTimeout(_ => window.location.href = config.routes.login, 2000),
        //  message = 'You are not authorized, please login again';
        // if (Storage.get(config.authProps[0]) === null && window.location.pathname !== config.routes.login) {
        //  Alert({
        //   type: 'error',
        //   message,
        //   cb
        //  });
        // }

        return configs;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return API;
  }
}

export default API_INSTANCE;
