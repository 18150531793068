import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";
import { getMdaIdByName } from "../../utils/helper";
import config from "../../utils/config";

export const getReports = createAsyncThunk(
  "/reports",
  ({ search, pageSize } = {}, thunkApi) => {
    try {
      const { pagination } = thunkApi.getState().users;
      const { departments } = thunkApi.getState().departments;
      const status =
        pagination.status?.toLowerCase() === "early"
          ? true
          : pagination.status?.toLowerCase() === "late"
          ? false
          : "";

      return new BACKEND().send({
        type: "get",
        to: `/clock/records/?pageNumber=${pagination.page}&pageSize=${
          pageSize || pagination.pageSize
        }&departmentId=${
          getMdaIdByName(pagination.filter, departments) || ""
        }&search=${search || pagination.search || ""}&isEarly=${status}`,
        useAlert: false,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const getUsers = createAsyncThunk(
  "/users/user",
  ({ search, pageSize } = {}, thunkApi) => {
    try {
      const { pagination } = thunkApi.getState().users;
      return new BACKEND().send({
        type: "get",
        to: `/users/?pageNumber=${pagination.page}&pageSize=${
          pageSize || pagination.pageSize
        }&search=${search || pagination.search || pagination.filter || ""}`,
        useAlert: false,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const uploadUsers = createAsyncThunk(
  "/users/upload",
  ({ payload, header }, thunkApi) => {
    try {
      console.log(payload);
      return new BACKEND().send({
        type: "post",
        to: "/auth/bulksignup",
        useAlert: true,
        payload,
        header,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const createUser = createAsyncThunk(
  "/users/create",
  (payload, thunkApi) => {
    try {
      return new BACKEND().send({
        type: "post",
        to: "/auth/createuser",
        useAlert: true,
        payload,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "/users/update",
  (payload, thunkApi) => {
    try {
      return new BACKEND().send({
        type: "post",
        to: "/auth/update-profile",
        useAlert: true,
        payload,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

const initialState = {
  loading: false,
  users: [],
  user: null,
  report: [],
  payload: {
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    department: "",
    staffId: "",
    bvn: "",
    branch: "",
  },
  downloadUrl: config.backendUrl.endsWith("/")
    ? config.backendUrl + ``
    : config.backendUrl + `/`,
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    length: 0,
    search: "",
    filter: "",
    status: "",
    startDate: "",
    endDate: "",
  },
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setPagination: (state, { payload }) => {
      state.pagination = { ...state.pagination, ...payload };
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload && payload.success) {
          state.users = payload.data.users;
          state.pagination.total = payload.data.count;
          state.pagination.length =
            state.pagination.pageSize * state.pagination.page;
        }
      })
      .addCase(getUsers.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(getReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReports.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload && payload.success) {
          state.report = payload.data.records;
          state.pagination.total = payload.data.count;
          state.pagination.length =
            state.pagination.pageSize * state.pagination.page;
        }
      })
      .addCase(getReports.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(uploadUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadUsers.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(uploadUsers.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(createUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(createUser.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(updateUser.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setPagination, setUser } = userSlice.actions;
export const getUserData = (state) => state.users;
export default userSlice.reducer;
