/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import HomeLayout from "../../layouts/Home";
import Box from "@mui/material/Box";
import { Button, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Table from "../../components/table/Table";
import TablePreloader from "../../components/Loader/TablePreloader";
import Menu from "../../utils/data.dropdown";
import SearchBar from "../../components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import * as departmentAction from "../../features/settings/departmentSlice";
import * as branchAction from "../../features/settings/branchSlice";
import * as deviceAction from "../../features/settings/deviceSlice";
import * as emailAction from "../../features/settings/EmailSlice";
import * as systemAction from "../../features/settings/system";
import { Link, useNavigate } from "react-router-dom";
import config from "../../utils/config";
import CancelModal from "../../components/Modals/Cancel";
import { Roles, getRole, tabList } from "../../utils/helper";
import { getAuthData, setTab } from "../../features/auth/authSlice";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";

const { routes } = config;

export default function Settings() {
  const dispatch = useDispatch(),
    navigate = useNavigate(),
    departmentStateData = useSelector(departmentAction.getDepartmentData),
    branchStateData = useSelector(branchAction.getBranchData),
    deviceStateData = useSelector(deviceAction.getDeviceData),
    emailStateData = useSelector(emailAction.getEmailData),
    systemStateData = useSelector(systemAction.getSystemData),
    authData = useSelector(getAuthData);
  const [activeTab, setActiveTab] = useState(authData?.activeTab || 1);
  const [deleteState, setDelete] = useState(false);
  const [dataId, setDataId] = useState("");
  const getTabById = (tabId) => tabList.find((tab) => tab.id === tabId);
  const [edit, setEdit] = useState(false);
  const [sort, setSort] = useState({ data: "Recently Updated", edit: false });
  const [date, setDate] = useState("");

  function setPagination(d, b) {
    switch (b || getTabById(activeTab).code) {
      case "Department":
        dispatch(departmentAction.setPagination(d));
        break;
      case "Branch":
        dispatch(branchAction.setPagination(d));
        break;
      case "Device":
        dispatch(deviceAction.setPagination(d));
        break;
      case "Email":
        dispatch(emailAction.setPagination(d));
        break;
      default:
        dispatch(departmentAction.setPagination(d));
    }
  }

  async function fetchData() {
    switch (getTabById(activeTab).code) {
      case "Department":
        await dispatch(departmentAction.getDepartments());
        break;
      case "Branch":
        await dispatch(branchAction.getBranches());
        break;
      case "Device":
        await dispatch(deviceAction.getDevices());
        break;
      case "Email":
        await dispatch(emailAction.getEmails());
        break;
      case "System":
        await dispatch(systemAction.getSystemResumptionTime());
        break;
      default:
        await dispatch(departmentAction.getDepartmentData());
    }
  }

  function getStateData() {
    switch (getTabById(activeTab).code) {
      case "Department":
        return departmentStateData;
      case "Branch":
        return branchStateData;
      case "Device":
        return deviceStateData;
      case "System":
        return systemStateData;
      case "Email":
        return emailStateData;
      default:
        return departmentStateData;
    }
  }

  async function handleDelete() {
    let action;
    switch (getTabById(activeTab).code) {
      case "Department":
        action = departmentAction.deleteDepartment;
        break;
      case "Branch":
        action = branchAction.deleteBranch;
        break;
      case "Device":
        return deviceStateData;
      case "Email":
        action = emailAction.deleteEmail;
        break;
      default:
        return departmentStateData;
    }

    if (action) {
      const res = await dispatch(action(dataId)).unwrap();
      if (res?.success) {
        setDelete(false);
        await fetchData();
      }
    }
  }

  useEffect(() => {
    if (Roles.superAdmin !== getRole())
      (async () =>
        await dispatch(
          branchAction.getBranchById(branchStateData?.branchId)
        ))();

    let cb = () => {};
    if (getStateData()?.pagination?.search) {
      setPagination({ page: 1 });
      cb = setTimeout((_) => (async () => await fetchData())(), 700);
    } else {
      (async () => await fetchData())();
    }

    return () => {
      clearTimeout(cb);
    };
  }, [
    getStateData()?.pagination?.search,
    getStateData()?.pagination?.page,
    activeTab,
  ]);

  const menu = new Menu({});
  const dropdownMenu = [
    menu.edit({ navigate, routes, route: routes.createSettings }),
    menu.delete({
      action: (state) => {
        setDataId(state?.id);
        setDelete(true);
      },
    }),
  ];

  const handleSearch = ({ target: { value } }) => {
    setPagination({ search: value });
  };

  const departmentData = departmentStateData?.departments?.map?.(
    (department, i) => ({
      Name: department?.department || "N/A",
      _data: { ...department, activeTabId: activeTab },
    })
  );

  const branchData = branchStateData?.branches?.map((branch, i) => ({
    Name: branch.branchName || "N/A",
    State: branch.state || "N/A",
    _data: { ...branch, activeTabId: activeTab },
  }));

  const deviceData = deviceStateData?.devices?.map((device, i) => ({
    Name: device.deviceName || "N/A",
    Branch: device?.Branch?.branchName || "N/A",
    IMEI: device.deviceImei || "N/A",
    "Serial No": device.deviceSerialNo || "N/A",
    _data: { ...device, activeTabId: activeTab },
  }));
  const emailData = emailStateData?.emails?.map((email, i) => ({
    email: email.email || "N/A",

    _data: { ...email, activeTabId: activeTab },
  }));

  const tableData = {
    departments: departmentData,
    branches: branchData,
    devices: deviceData,
    emails: emailData,
  };

  const data = tableData[getTabById(activeTab).name.toLowerCase()];

  const handleBranchDate = async (_) => {
    console.log(date);
    const res = await dispatch(
      branchAction.updateBranch({
        resumptionTime: date,
        branchId: branchStateData?.branchId,
      })
    ).unwrap();
    if (res?.success) {
      setEdit(false);
      // setDate(branchStateData?.branch?.resumptionTime)
    }
  };

  return (
    <HomeLayout>
      <div className="mb-10">
        <Typography
          variant="h6"
          noWrap
          component="div"
          color="#040316"
          fontSize="32px"
          className="sofiaProBold"
          sx={{ my: 3 }}
        >
          Settings
        </Typography>

        <div className="my-10 border-b-4 rounded border-[var(--c-primary-1)] __unique_cb">
          <div className="flex justify-between mt-5 relative bottom-[-4px] px-3">
            {tabList?.map((list) => (
              <p
                key={list.id}
                onClick={(_) => {
                  setPagination({ search: "" });
                  setActiveTab((_) => list.id);
                  dispatch(setTab(list.id));
                }}
                className={`${
                  activeTab === list.id &&
                  "border-b-4 text-[var(--c-primary-0)]"
                } border-[var(--c-primary-0)] px-1 hover:text-[var(--c-primary-0)] cursor-pointer`}
              >
                {list.name}
              </p>
            ))}
          </div>
        </div>

        {getTabById(activeTab).name !== "System" ? (
          <>
            <div className="lg:grid lg:grid-cols-3">
              <div className="col-span-2 lg:mb-0 mb-6 ">
                <SearchBar
                  {...{
                    onChange: handleSearch,
                    value: getStateData()?.pagination?.search,
                    placeholder: `Search by ${
                      getTabById(activeTab).code
                    } Name...`,
                  }}
                />
              </div>
              <div className="flex lg:justify-end justify-center">
                <Button
                  state={{ activeTabId: activeTab }}
                  disabled={getStateData().loading}
                  component={Link}
                  to={config.routes.createSettings}
                  sx={{ textTransform: "capitalize", borderRadius: ".5em" }}
                  variant="text"
                  size="small"
                  className="h-[42px] font-[500] md:text-[15px] text-[10px] rounded-[10px] text-white py-2 px-5 bg-[#D70900] hover:bg-[#FF5C5C]"
                >
                  {" "}
                  Create New {getTabById(activeTab).code}
                </Button>
              </div>
            </div>

            <Box className="">
              <div className="w-full">
                <Container
                  className={`${
                    (data?.length > 0 || getStateData().loading) &&
                    "mt-8 py-5 bg-[var(--c-bg-color)]"
                  } rounded-lg lg:min-w-full`}
                  sx={{ px: 0 }}
                >
                  {data?.length > 0 && (
                    <Box className="flex justify-between mb-2">
                      <Typography fontFamily={"Lota"}>
                        All {getTabById(activeTab).name}
                      </Typography>
                    </Box>
                  )}

                  {getStateData().loading && data?.length === 0 ? (
                    <TablePreloader />
                  ) : (
                    <Table
                      {...{
                        data,
                        setPagination: (a) =>
                          setPagination(a, getTabById(activeTab).code),
                        pagination: getStateData()?.pagination,
                        isLoading: getStateData()?.loading,
                        dropdownMenu,
                        tableMsg: [
                          `No ${getTabById(activeTab).code} created,`,
                          "Kindly check back later.",
                        ],
                      }}
                    />
                  )}
                </Container>
              </div>
            </Box>
          </>
        ) : (
          <section className="mx-2">
            <Typography
              variant="h6"
              noWrap
              component="div"
              color="#040316"
              fontSize="32px"
              className="sofiaPro "
              sx={{ my: 3 }}
            >
              System
            </Typography>

            <p className="text-semibold text-gray-500">
              {" "}
              <span className="border-b-2 border-[var(--c-primary-0)]">
                Core
              </span>{" "}
              Settings
            </p>

            <Box className="grid xl:grid-cols-3 lg:grid-cols-2 gap-">
              <div className="mt-3">
                <p> Default Resumption Time</p>
                <div className="flex">
                  <p className="text-sm text-gray-600 ml-1">
                    {getStateData().system ? (
                      dayjs(getStateData().system).format("hh:mm A")
                    ) : (
                      <span>Not set</span>
                    )}
                  </p>
                  {!edit && Roles.superAdmin === getRole() && (
                    <Button
                      variant="outlined"
                      onClick={(_) => setEdit(true)}
                      className="p-0 ml-3 capitalize"
                      color="error"
                    >
                      Edit
                    </Button>
                  )}
                </div>

                {edit && Roles.superAdmin === getRole() && (
                  <div className="ml-4 mt-1">
                    <TextField
                      type="time"
                      variant="standard"
                      className="p-0 mt-0 h-[130px] border-0"
                      sx={{ height: "20px", padding: "0" }}
                      style={{ height: "20px", padding: "0" }}
                    />
                    <Button
                      variant="outlined"
                      className="p-0 ml-3 capitalize"
                      color="error"
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      onClick={(_) => setEdit(false)}
                      className="p-0 ml-1 capitalize"
                      color="error"
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </div>

              <div className="mt-3">
                <p> Branch Resumption Time</p>
                <div className="flex">
                  <p className="text-sm text-gray-600 ml-1">
                    {branchStateData?.branch?.resumptionTime ? (
                      (date || branchStateData?.branch?.resumptionTime) +
                      (Number(
                        (date || branchStateData?.branch?.resumptionTime).split(
                          ":"
                        )[0]
                      ) > 11
                        ? " PM"
                        : " AM")
                    ) : (
                      <span>Not set</span>
                    )}
                  </p>
                  {!edit && Roles.admin === getRole() && (
                    <Button
                      variant="outlined"
                      onClick={(_) => setEdit(true)}
                      className="p-0 ml-3 capitalize"
                      color="error"
                    >
                      Edit
                    </Button>
                  )}
                </div>

                {edit && Roles.admin === getRole() && (
                  <div className="ml-4 mt-1">
                    <TextField
                      type="time"
                      variant="standard"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      className="p-0 mt-0 h-[130px] border-0"
                      sx={{ height: "20px", padding: "0" }}
                      style={{ height: "20px", padding: "0" }}
                    />
                    <LoadingButton
                      variant="outlined"
                      loading={branchStateData.loading}
                      onClick={handleBranchDate}
                      className="p-0 ml-3 capitalize"
                      color="error"
                    >
                      Save
                    </LoadingButton>
                    <Button
                      variant="contained"
                      onClick={(_) => setEdit(false)}
                      className="p-0 ml-1 capitalize"
                      color="error"
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </div>
            </Box>

            <p className="text-semibold text-gray-500 mt-5">
              {" "}
              <span className="border-b-2 border-[var(--c-primary-0)]">
                Table
              </span>{" "}
              Settings
            </p>

            <Box className="grid xl:grid-cols-3 lg:grid-cols-2 gap-">
              <div className="mt-3">
                <p> Sort by</p>
                <div className="flex">
                  <p className="text-sm text-gray-600 ml-1">
                    <span>{sort.data}</span>
                  </p>
                  {!sort.edit && Roles.superAdmin === getRole() && (
                    <Button
                      variant="outlined"
                      onClick={(_) => setSort({ ...sort, edit: true })}
                      className="p-0 ml-3 capitalize"
                      color="error"
                    >
                      Edit
                    </Button>
                  )}
                </div>
                {sort.edit && (
                  <div className="">
                    <select
                      value={sort.data}
                      onChange={(e) => {
                        setSort({ edit: false, data: e.target.value });
                      }}
                    >
                      <option value="Recently Updated">Recently Updated</option>
                      <option value="Ascending">Ascending</option>
                      <option value="Descending">Descending</option>
                    </select>
                  </div>
                )}
              </div>
            </Box>
          </section>
        )}
      </div>

      <CancelModal
        {...{
          open: deleteState,
          loading: getStateData()?.modalLoading,
          handleSuccess: handleDelete,
          handleClose: (_) => setDelete(false),
          btnTexts: ["Cancel", "Delete"],
          title: "Delete",
          subtitle:
            "Are you sure you want to delete this " +
            getTabById(activeTab).code.toLocaleLowerCase() +
            "?",
        }}
      />
    </HomeLayout>
  );
}
