import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";

export const getSystemResumptionTime = createAsyncThunk('/settings/system', (_, thunkApi) => {
 try {
  // const { pagination } = thunkApi.getState().system
  return new BACKEND().send({
   type: 'get',
   to: `/defaultresumptiontime`,
   useAlert: false
 })
 } catch (error) {
   console.error(error);
 }
})

 

const initialState = {
 loading: false,
 system: [],
 payload: {
  "SystemName": "",
  "SystemImei": "",
  "SystemSerialNo": "",
  "branchId": ""
},
};

export const systemSlice = createSlice({
 name: 'system',
 initialState,
 reducers: {
  setPagination: (state, { payload }) => {
   state.pagination = {...state.pagination, ...payload}
  }
 },
 extraReducers: (builder => {
  builder.addCase(getSystemResumptionTime.pending, (state) => {
   state.loading = true;
  })
  .addCase(getSystemResumptionTime.fulfilled, (state, { payload }) => {
   state.loading = false;
   if(payload.success) {
    state.system = payload.data
   }
  })
  .addCase(getSystemResumptionTime.rejected, state =>{
   state.loading = false
  })
  
 })
})

export const { setPagination } = systemSlice.actions
export const getSystemData = state => state.system
export default systemSlice.reducer;