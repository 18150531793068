import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";
import { loggedInUser } from "../../utils/helper";

export const getBranches = createAsyncThunk('/settings/branches', ({search, pageSize} = {}, thunkApi) => {
 try {
  const { pagination } = thunkApi.getState().branches
  return new BACKEND().send({
   type: 'get',
   to: `/branch/branches/?pageNumber=${pagination.page}&pageSize=${pageSize || pagination.pageSize}&search=${search || pagination.search || pagination.filter || ""}`,
   useAlert: false
 })
 } catch (error) {
   console.error(error);
 }
})

export const getBranchById = createAsyncThunk('/settings/getBranchById', (id, thunkApi) => {
 try {
  return new BACKEND().send({
   type: 'get',
   to: `/branch/branch/${id}`,
   useAlert: false
 })
 } catch (error) {
   console.error(error);
 }
})


export const createBranch = createAsyncThunk('/settings/branches/create', ({branchId, ...payload}, thunkApi) => {
  try {
   return new BACKEND().send({
    type: 'post',
    to: "/branch/create",
    useAlert: true,
    payload
  })
  } catch (error) {
    console.error(error);
  }
 })

 export const updateBranch = createAsyncThunk('/settings/branches/update', ({branchId, ...payload}, thunkApi) => {
  try {
   return new BACKEND().send({
    type: 'patch',
    to: `/branch/branch/${branchId}`,
    useAlert: true,
    payload
  })
  } catch (error) {
    console.error(error);
  }
 })

 export const deleteBranch = createAsyncThunk('/settings/branches/delete', (branchId, thunkApi) => {
  try {
   return new BACKEND().send({
    type: 'delete',
    to: `/branch/branch/${branchId}`,
    useAlert: true,
  })
  } catch (error) {
    console.error(error);
  }
 })
 

const initialState = {
 loading: false,
 modalLoading: false,
 branches: [],
 branchId: loggedInUser && JSON.parse(loggedInUser)?.branchId,
 branch: null,
 payload: {
  "branchName": "",
  "branchAddress": "",
  "state": "",
  "createdBy": ""
},
 pagination: {
  page: 1,
  pageSize: 10,
  total: 0,
  length: 0,
  search: '',
  filter: ''
 }
};

export const branchSlice = createSlice({
 name: 'branches',
 initialState,
 reducers: {
  setPagination: (state, { payload }) => {
   state.pagination = {...state.pagination, ...payload}
  }
 },
 extraReducers: (builder => {
  builder.addCase(getBranches.pending, (state) => {
   state.loading = true;
  })
  .addCase(getBranches.fulfilled, (state, { payload }) => {
   state.loading = false;
   if(payload.success) {
    state.branches = payload.data.branches
    state.pagination.total = payload.data.count;
    state.pagination.length = state.pagination.pageSize * state.pagination.page
   }
  })
  .addCase(getBranches.rejected, state => {state.loading = false})
  
  builder.addCase(createBranch.pending, (state) => {
   state.loading = true;
  })
  .addCase(createBranch.fulfilled, (state, { payload }) => {
   state.loading = false;
  })
  .addCase(createBranch.rejected, state => {state.loading = false})
  
  builder.addCase(updateBranch.pending, (state) => {
   state.loading = true;
  })
  .addCase(updateBranch.fulfilled, (state, { payload }) => {
   state.loading = false;
  })
  .addCase(updateBranch.rejected, state => {
    state.loading = false
  })
  
  builder.addCase(deleteBranch.pending, (state) => {
   state.modalLoading = true;
  })
  .addCase(deleteBranch.fulfilled, (state, { payload }) => {
   state.modalLoading = false;
  })
  .addCase(deleteBranch.rejected, state => {
    state.modalLoading = false
  })
  
  builder.addCase(getBranchById.pending, (state) => {
   state.modalLoading = true;
  })
  .addCase(getBranchById.fulfilled, (state, { payload }) => {
   state.modalLoading = false;
   if(payload?.success){
    state.branch = payload?.data?.branch
   }
  })
  .addCase(getBranchById.rejected, state => {
    state.modalLoading = false
  })
  
 })
})

export const { setPagination } = branchSlice.actions
export const getBranchData = state => state.branches
export default branchSlice.reducer;