import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";

export const getEmails = createAsyncThunk(
  "/settings/email",
  ({ search, pageSize } = {}, thunkApi) => {
    try {
      const { pagination } = thunkApi.getState().emails;
      return new BACKEND().send({
        type: "get",
        to: `/reportemails/?pageNumber=${pagination.page}&pageSize=${
          pageSize || pagination.pageSize
        }&search=${search || pagination.search || pagination.filter || ""}`,
        useAlert: false,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const createEmail = createAsyncThunk(
  "/settings/email/create",
  ({ emailId, ...payload }, thunkApi) => {
    try {
      return new BACKEND().send({
        type: "post",
        to: "/reportemails",
        useAlert: true,
        payload,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const updateEmail = createAsyncThunk(
  "/settings/email/update",
  ({ emailId, email }, thunkApi) => {
    try {
      return new BACKEND().send({
        type: "patch",
        to: `/reportemails/${emailId}`,
        useAlert: true,
        payload: { email },
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const deleteEmail = createAsyncThunk(
  "/settings/email/delete",
  (emailId, thunkApi) => {
    try {
      return new BACKEND().send({
        type: "delete",
        to: `/reportemails/${emailId}`,
        useAlert: true,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

const initialState = {
  loading: false,
  modalLoading: false,
  emails: [],
  payload: {
    email: "",
    createdBy: "",
  },
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    length: 0,
    search: "",
  },
};

export const emailSlice = createSlice({
  name: "emails",
  initialState,
  reducers: {
    setPagination: (state, { payload }) => {
      state.pagination = { ...state.pagination, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmails.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.success) {
          state.emails = payload?.data?.reportemails;
          state.pagination.total = payload.data.count;
          state.pagination.length =
            state.pagination.pageSize * state.pagination.page;
        }
      })
      .addCase(getEmails.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(deleteEmail.pending, (state) => {
        state.modalLoading = true;
      })
      .addCase(deleteEmail.fulfilled, (state, { payload }) => {
        state.modalLoading = false;
      })
      .addCase(deleteEmail.rejected, (state) => {
        state.modalLoading = false;
      });

    builder
      .addCase(createEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(createEmail.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(createEmail.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(updateEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateEmail.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(updateEmail.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setPagination } = emailSlice.actions;
export const getEmailData = (state) => state.emails;
export default emailSlice.reducer;
