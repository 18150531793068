import SplashImage from "../assets/images/login.svg";
import Brand from "../assets/images/brand.svg";
import ArrowBack from "../assets/images/arrow-back.svg";
import Power from "../assets/images/power.svg";
import Dashboard from "../assets/images/dashboard.svg";
import ArrowTop from "../assets/images/arrow-top.svg";
import Users from "../assets/images/user.svg";
import Settings from "../assets/images/gear.svg";
import Excel from "../assets/images/excel.svg";
import EmptyState from "../assets/images/emptyState.svg";
import DatePicker from "../assets/images/datePicker.svg";

export const ISplashImage = SplashImage;
export const IArrowBack = ArrowBack;
export const IBrand = Brand;
export const IPower = Power;
export const IDashboard = Dashboard;
export const IArrowTop = ArrowTop;
export const IUsers = Users;
export const ISettings = Settings;
export const IExcel = Excel;
export const IEmptyState = EmptyState;
export const IDatePicker = DatePicker;
