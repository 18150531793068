import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";

export const getDevices = createAsyncThunk('/settings/devices', ({search, pageSize} = {}, thunkApi) => {
 try {
  const { pagination } = thunkApi.getState().devices
  return new BACKEND().send({
   type: 'get',
   to: `/device/devices/?pageNumber=${pagination.page}&pageSize=${pageSize || pagination.pageSize}&search=${search || pagination.search || pagination.filter || ""}`,
   useAlert: false
 })
 } catch (error) {
   console.error(error);
 }
})


export const createDevice = createAsyncThunk('/settings/devices/create', (payload, thunkApi) => {
  try {
   return new BACKEND().send({
    type: 'post',
    to: "/device/register",
    useAlert: true,
    payload
  })
  } catch (error) {
    console.error(error);
  }
 })

 export const updateDevice = createAsyncThunk('/settings/devices/update', (payload, thunkApi) => {
  try {
   return new BACKEND().send({
    type: 'post',
    to: "/device/update-device",
    useAlert: true,
    payload
  })
  } catch (error) {
    console.error(error);
  }
 })
 

const initialState = {
 loading: false,
 devices: [],
 payload: {
  "deviceName": "",
  "deviceImei": "",
  "deviceSerialNo": "",
  "branchId": ""
},
 pagination: {
  page: 1,
  pageSize: 10,
  total: 0,
  length: 0,
  search: '',
  filter: ''
 }
};

export const deviceSlice = createSlice({
 name: 'devices',
 initialState,
 reducers: {
  setPagination: (state, { payload }) => {
   state.pagination = {...state.pagination, ...payload}
  }
 },
 extraReducers: (builder => {
  builder.addCase(getDevices.pending, (state) => {
   state.loading = true;
  })
  .addCase(getDevices.fulfilled, (state, { payload }) => {
   state.loading = false;
   if(payload.success) {
    state.devices = payload.data.devices
    state.pagination.total = payload.data.count;
    state.pagination.length = state.pagination.pageSize * state.pagination.page
   }
  })
  .addCase(getDevices.rejected, state => {state.loading = false})
  
  builder.addCase(createDevice.pending, (state) => {
   state.loading = true;
  })
  .addCase(createDevice.fulfilled, (state, { payload }) => {
   state.loading = false;
  })
  .addCase(createDevice.rejected, state => {state.loading = false})
  
  builder.addCase(updateDevice.pending, (state) => {
   state.loading = true;
  })
  .addCase(updateDevice.fulfilled, (state, { payload }) => {
   state.loading = false;
  })
  .addCase(updateDevice.rejected, state => {
    state.loading = false
  })
  
 })
})

export const { setPagination } = deviceSlice.actions
export const getDeviceData = state => state.devices
export default deviceSlice.reducer;