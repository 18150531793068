 /* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Box from "@mui/material/Box";
import {
	Typography,
	FormControl,
	TextField,
 Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { vCreateUser } from "../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import { createUser, getUserData, updateUser } from "../../features/users/userSlice";
import HomeLayout from "../../layouts/Home";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SelectInput from "../../components/SelectInput";
import { useEffect } from "react";
import { getBranchData, getBranches } from "../../features/settings/branchSlice";
import { getDepartmentData, getDepartments } from "../../features/settings/departmentSlice";
import { getBranchIdByName, getMdaIdByName } from "../../utils/helper";
import PhoneNumberInput from "../../components/Phone/PhoneInput";

const CreateUser = () => {
  const dispatch = useDispatch(),
  navigate = useNavigate(),
		{ state } = useLocation(),
		params = useParams(),
		{ branches, loading: branchLoading } = useSelector(getBranchData),
		{ departments, loading: mdaLoading } = useSelector(getDepartmentData);

		const stateModel = {
			userId: params?.id,
		"firstName": state?.firstName || '',
		"middleName": state?.middleName || '',
		"lastName": state?.lastName || '',
		"department": state?.Department?.department || '',
		"staffId": state?.staffId || '',
		"bvn": state?.bvn || '',
		email: state?.email || '',
		branch: state?.Branch?.branchName || '',
		phoneNumber: state?.phoneNumber || '',
		}

		const { payload, loading} = useSelector(getUserData),
			{
				values,
				handleBlur,
				handleChange,
				handleSubmit,
				setValues,
				errors,
				touched,
				handleFocus,
			} = useFormik({
				validationSchema: vCreateUser,
				initialValues: state?.editable ? stateModel : payload, 
				onSubmit:  async (values) => {
					let payload = {...values}
					payload.branchId = getBranchIdByName(values.branch, branches);
					payload.departmentId = getMdaIdByName(values.department, departments);
					delete payload.branch;
					delete payload.department;
					delete payload.organization;
					let action = state?.editable ? updateUser : createUser;
					const res = await dispatch(action(payload)).unwrap();
					res.success && navigate(-1);
				},
			});
			

useEffect(() => {
	dispatch(getBranches())
	dispatch(getDepartments())
}, [])


	return (
  <HomeLayout {...{showNavigation: true}}>
     		<Typography
					variant="h6"
					noWrap
					component="div"
					color="#040316"
					fontSize="32px"
					className="sofiaProBold"
					sx={{ my: 3 }}>
					Users
				</Typography>

<div  
className="pb-10"
				style={{ borderTopLeftRadius: "100" }}>
    <Box sx={{bgcolor: "var(--c-primary-2)", p: "1em",}} className='rounded-md'>
     <Typography className="text-[400] text-xl mb-8 ml-3 text-[#040316]">
      {state?.editable ? "Edit" : "Create"} User
     </Typography> 

				<Box
					className="rounded-lg lg:px-[4em] px-[1em]"
					noValidate
					component="form"
					onSubmit={handleSubmit}>
					<Box className="grid xl:grid-cols-3 lg:grid-cols-2 gap-4 m-auto">
					
						<FormControl className="w-full border-none lg:mt-5">
       <label htmlFor='firstName'>First Name</label>
							<TextField
								className="w-full bg-white"
								id="firstName"
								name="firstName"
								value={values?.firstName}
								required
								onFocus={handleFocus}
								onChange={handleChange}
								onBlur={handleBlur}
								error={errors?.firstName && touched?.firstName}
								helperText={
									errors?.firstName && touched?.firstName ? errors?.firstName : ""
								}
							/>
						</FormControl>

      <FormControl className="w-full border-none lg:mt-5">
      <label htmlFor='middleName'> Other Name </label>
							<TextField
								className="w-full bg-white"
								id="firstName"
								name="middleName"
								value={values.middleName}
								required
								onFocus={handleFocus}
								onChange={handleChange}
								onBlur={handleBlur}
								error={errors.middleName && touched.middleName}
								helperText={
									errors.middleName && touched.middleName ? errors.middleName : ""
								}
							/>
						</FormControl>

						<FormControl className="w-full border-none lg:mt-5">
      <label htmlFor='lastName'> Last Name </label>
							<TextField
								className="w-full bg-white"
								id="lastName"
								name="lastName"
								value={values.lastName}
								required
								onFocus={handleFocus}
								onChange={handleChange}
								onBlur={handleBlur}
								error={errors.lastName && touched.lastName}
								helperText={
									errors.lastName && touched.lastName ? errors.lastName : ""
								}
							/>
						</FormControl>

						<FormControl className="w-full border-none lg:mt-5">
      <label htmlFor='email'> Email Address</label>
							<TextField
								className="w-full bg-white"
								id="email"
								name="email"
        type='email'
								value={values.email}
								onFocus={handleFocus}
								onChange={handleChange}
								onBlur={handleBlur}
								error={errors.email && touched.email}
								helperText={
									errors.email && touched.email ? errors.email : ""
								}
							/>
						</FormControl>

						<FormControl required className="w-full lg:mt-5">
						<label htmlFor='phoneNumber'> Phone Number</label>
						<PhoneNumberInput
							{...{
								phoneNo: values.phoneNumber,
								setPhoneNo: (val) => setValues({ ...values, phoneNumber: val }),
								error: errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : "",
								onFocus: handleFocus,
								onBlur: handleBlur,
							}}
						/>
					</FormControl>
					
					<FormControl className="w-full border-none lg:mt-5">
      <label htmlFor='bvn'> BVN</label>
							<TextField
								className="w-full bg-white"
								id="bvn"
								name="bvn"
								value={values.bvn}
								required
								onFocus={handleFocus}
								onChange={handleChange}
								onBlur={handleBlur}
								error={errors.bvn && touched.bvn}
								helperText={
									errors.bvn && touched.bvn ? errors.bvn : ""
								}
							/>
						</FormControl>
					
					<FormControl className="w-full border-none lg:mt-5">
      <label htmlFor='staffId'> Staff ID </label>
							<TextField
								className="w-full bg-white"
								id="staffId"
								name="staffId"
								value={values.staffId}
								required
								onFocus={handleFocus}
								onChange={handleChange}
								onBlur={handleBlur}
								error={errors.staffId && touched.staffId}
								helperText={
									errors.staffId && touched.staffId
										? errors.staffId
										: ""
								}
							/>
						</FormControl>

						<FormControl className="w-full border-none lg:mt-5">
	<label htmlFor="branch"> Branch </label>
	<SelectInput
	{...{
		className: "w-full bg-white",
		value: values.branch,
		id: "branch",
		name: "branch",
		handleChange,
		handleBlur,
		required: false,
		handleFocus,
		error: errors.branch && touched.branch,
		helperText: errors.branch,
		loading: branchLoading, 
		options: branches.map(branch=>branch.branchName),
	}}
/>
</FormControl>

<FormControl className="w-full border-none lg:mt-5">
	<label htmlFor="department"> Department </label>
	<SelectInput
	{...{
		className: "w-full bg-white",
		value: values.department,
		id: "department",
		name: "department",
		handleChange,
		handleBlur,
		required: false,
		handleFocus,
		error: errors.department && touched.department,
		helperText: errors.department,
		loading: mdaLoading, 
		options: departments?.map?.(department=> department?.department),
	}}
/>
</FormControl>

    
					</Box>
					
					<Box className="flex justify-end">
						<Button
							onClick={(_) => navigate(-1)}
							className="my-10 w-1/2 lg:w-[120px] rounded-[10px] bg-[var(--c-primary-1)] hover:bg-[var(--c-danger-3)] text-[var(--c-primary-0)] hover:text-white self-end shadow-none normal-case mr-5"
							sx={{
								".MuiLoadingButton-loadingIndicatorCenter": { color: "white" },
							}}
							size="large"
							variant="contained"
							fullWidth>
							Cancel
						</Button>

						<LoadingButton
							className="my-10 w-1/2 lg:w-[120px] rounded-[10px] bg-[var(--c-primary-0)] hover:bg-[#FF5C5C]  self-end shadow-none normal-case"
							sx={{
								".MuiLoadingButton-loadingIndicatorCenter": {
									color: "white !important",
								},
							}}
							size="large"
							type="submit"
							variant="contained"
							disabled={loading}
							loading={loading}
							fullWidth>
        {state?.editable ? "Update" : "Submit"}
						</LoadingButton>
					</Box>
				</Box>
    </Box>
			</div>
  </HomeLayout>
	);
};

export default CreateUser;
