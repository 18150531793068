import {
  createSlice,
  createAsyncThunk
} from "@reduxjs/toolkit";
import config from "../../utils/config";
import Storage from "../../utils/storage";
import BACKEND from "../../utils/backend";

export const uploadFile = createAsyncThunk('auth/uploadFile', async ({
  formData: payload,
  config
}, thunkAPI) => {
  try {
    return new BACKEND().send({
      type: 'post',
      to: '/upload-file',
      useAlert: true,
      header: config,
      payload
    })

  } catch (error) {
    thunkAPI.rejectWithValue("An error occurred somewhere")
  }
})

export const asyncLogin = createAsyncThunk('/auth/login', async (payload, thunkAPI) => {
  try {
    return new BACKEND().send({
      type: 'post',
      to: '/auth/login/?deviceType=web',
      useAlert: true,
      payload
    })

  } catch (error) {
    thunkAPI.rejectWithValue("An error occurred somewhere")
  }
})


export const getStates = createAsyncThunk('/auth/states', async (_, thunkAPI) => {
  try {
    return new BACKEND().send({
      type: 'get',
      to: '/states',
      useAlert: false,
    })

  } catch (error) {
    thunkAPI.rejectWithValue("An error occurred somewhere")
  }
})


const initialState = {
  payload: {
    email: "",
    password: '',
  },
  loading: false,
  states: [],
  activeTab: null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setPayload: (state, {payload}) => {
      state.payload = {...state.payload, ...payload}
    },
      setTab: (state, {payload}) => {
        state.activeTab = payload
      },
    logout: () => {
      Storage.remove(config.authProps[0])
      Storage.remove(config.authProps[1])
    }
  },
  extraReducers: (builder) => {
   
    /** Login Builder **/
    builder
    .addCase(asyncLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(asyncLogin.fulfilled, (state, {
        payload
      }) => {
        state.loading = false;
        if (payload.success) {
            Storage.set(config.authProps[0], payload.data.token)
            Storage.set(config.authProps[1], payload.data.user)  
        }
      })
      .addCase(asyncLogin.rejected, (state) => {
        state.loading = false;
      })
      /** Login Builder |END| **/
    .addCase(getStates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStates.fulfilled, (state, {
        payload
      }) => {
        state.loading = false;
        if (payload.success) {
            state.states = payload?.data?.states?.map(state=>state?.state);   
        }
      })
      .addCase(getStates.rejected, (state) => {
        state.loading = false;
      });

  },

})

export const getAuthData = state => state.auth;
export const {
  logout,
  setModal,
  setOtp,
  setEmail,
  setLogo,
  setImages,
  setCurrentScreen,
  setPayload,
  setTab
} = authSlice.actions
export default authSlice.reducer;