import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Auth from "./pages/Login";
import config from "./utils/config";
import router from './pages/Routes'
import ProtectedRoutes from './components/others/ProtectedRoutes'

function App() {
	const { routes } = config;
	function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

	return (
		<BrowserRouter>
			<div
				className="App m-2 mx-auto">
				<Routes>
					<Route
						{...{
							path: routes.home,
							element: <Auth />,
						}}
					/>

					<Route element={<ProtectedRoutes />}>
						{router.map((p, i) => (
							<Route key={i} {...p} />
						))}
					</Route>
				</Routes>
				<ScrollToTop/>
			</div>
		</BrowserRouter>
	);
}

export default App;
