/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Typography, FormControl, TextField, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import {
  vCreateBranch,
  vCreateDevice,
  vCreateEmail,
  vCreateMda,
} from "../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import HomeLayout from "../../layouts/Home";
import { useLocation, useNavigate } from "react-router-dom";
import SelectInput from "../../components/SelectInput";
import { getBranchIdByName, loggedInUser, tabList } from "../../utils/helper";
import * as departmentAction from "../../features/settings/departmentSlice";
import * as branchAction from "../../features/settings/branchSlice";
import * as deviceAction from "../../features/settings/deviceSlice";
import * as emailAction from "../../features/settings/EmailSlice";
import { getAuthData, getStates, setTab } from "../../features/auth/authSlice";

const Create = () => {
  const dispatch = useDispatch(),
    navigate = useNavigate(),
    { state } = useLocation(),
    [activeTab, setActiveTab] = useState(state.activeTabId),
    getTabById = (tabId) => tabList.find((tab) => tab.id === tabId),
    { states, loading: stateLoading } = useSelector(getAuthData),
    departmentStateData = useSelector(departmentAction.getDepartmentData),
    branchStateData = useSelector(branchAction.getBranchData),
    deviceStateData = useSelector(deviceAction.getDeviceData),
    emailStateData = useSelector(emailAction.getEmailData);

  function getStateData() {
    switch (getTabById(activeTab).code) {
      case "Department":
        return departmentStateData;
      case "Branch":
        return branchStateData;
      case "Device":
        return deviceStateData;
      case "Email":
        return emailStateData;
      default:
        return departmentStateData;
    }
  }
  const stateModel = {
      department: state.department || "",
      branchName: state?.branchName || "",
      branchAddress: state?.branchAddress || "",
      branch: state.Branch?.branchName || "",
      state: state?.state?.toUpperCase() || "",
      deviceName: state.deviceName || "",
      deviceSerialNo: state.deviceSerialNo || "",
      deviceImei: state.deviceImei || "",
      email: state.email || "",
    },
    {
      values,
      handleBlur,
      handleChange,
      handleSubmit,
      errors,
      touched,
      handleFocus,
    } = useFormik({
      validationSchema: getValidationSchema(getTabById(activeTab).code),
      initialValues: state.editable ? stateModel : getStateData().payload,
      onSubmit: async (values) => {
        let payload = { ...values };
        const { id } = JSON.parse(loggedInUser);
        let action;
        switch (getTabById(activeTab).code) {
          case "Department":
            action = state.editable
              ? departmentAction.updateDepartment
              : departmentAction.createDepartment;
            payload.departmentId = state.id;
            break;
          case "Branch":
            action = state.editable
              ? branchAction.updateBranch
              : branchAction.createBranch;
            payload.branchId = state.id;
            break;
          case "Device":
            action = state.editable
              ? deviceAction.updateDevice
              : deviceAction.createDevice;
            payload.branchId = getBranchIdByName(
              values.branch,
              branchStateData.branches
            );
            delete payload.branch;
            break;
          case "Email":
            action = state.editable
              ? emailAction.updateEmail
              : emailAction.createEmail;
            payload.emailId = state.id;
            break;
          default:
            action = departmentAction;
        }

        const res = await dispatch(
          action({ ...payload, createdBy: id })
        ).unwrap();
        res.success && navigate(-1, { state: { activeTabId: activeTab } });
      },
    });

  function getValidationSchema(name) {
    switch (name) {
      case "Department":
        return vCreateMda;
      case "Branch":
        return vCreateBranch;
      case "Device":
        return vCreateDevice;
      case "Email":
        return vCreateEmail;
      default:
        return vCreateMda;
    }
  }

  useEffect(() => {
    getTabById(activeTab).code === "Branch" &&
      states.length === 0 &&
      (async () => await dispatch(getStates()))();

    getTabById(activeTab).code === "Device" &&
      (async () => await dispatch(branchAction.getBranches()))();
  }, [activeTab]);

  console.log(errors);

  return (
    <HomeLayout {...{ showNavigation: true }}>
      <Typography
        variant="h6"
        noWrap
        component="div"
        color="#040316"
        fontSize="32px"
        className="sofiaProBold"
        sx={{ my: 3 }}
      >
        Settings
      </Typography>

      <div className="my-10 border-b-4 rounded border-[var(--c-primary-1)] __unique_cb">
        <div className="flex justify-between mt-5 relative bottom-[-4px] px-3">
          {tabList.map((list) => (
            <p
              key={list.id}
              onClick={
                state.editable
                  ? (_) => {}
                  : (_) => {
                      setActiveTab((_) => list.id);
                      dispatch(setTab(list.id));
                    }
              }
              className={`${
                activeTab === list.id && "border-b-4 text-[var(--c-primary-0)]"
              } border-[var(--c-primary-0)] px-1 ${
                !state.editable
                  ? "hover:text-[var(--c-primary-0)] cursor-pointer"
                  : "cursor-not-allowed"
              }`}
            >
              {list.name}
            </p>
          ))}
        </div>
      </div>

      <div className="pb-10">
        <Box
          sx={{ bgcolor: "var(--c-primary-2)", p: "1em" }}
          className="rounded-md"
        >
          <Typography className="text-[400] text-xl mb-8 ml-3 text-[#040316]">
            {state.editable ? "Edit" : "Create New"}{" "}
            {getTabById(activeTab).code}
          </Typography>

          <Box
            className="rounded-lg lg:px-[4em] px-[1em]"
            noValidate
            component="form"
            onSubmit={handleSubmit}
          >
            <Box className="grid lg:grid-cols-2 gap-4 m-auto">
              {getTabById(activeTab).code === "Device" ? (
                <>
                  <FormControl className="w-full border-none lg:mt-5">
                    <label htmlFor="deviceName">Device Name</label>
                    <TextField
                      className="w-full bg-white"
                      id="deviceName"
                      name="deviceName"
                      value={values.deviceName}
                      required
                      onFocus={handleFocus}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.deviceName && touched.deviceName}
                      helperText={
                        errors.deviceName && touched.deviceName
                          ? errors.deviceName
                          : ""
                      }
                    />
                  </FormControl>

                  <FormControl className="w-full border-none lg:mt-5">
                    <label htmlFor="branch"> Branch </label>
                    <SelectInput
                      {...{
                        className: "w-full bg-white",
                        value: values.branch,
                        id: "branch",
                        name: "branch",
                        handleChange,
                        handleBlur,
                        required: false,
                        handleFocus,
                        error: errors.branch && touched.branch,
                        helperText: errors.branch,
                        options: branchStateData.branches?.map(
                          (branch) => branch?.branchName
                        ),
                      }}
                    />
                  </FormControl>

                  <FormControl className="w-full border-none lg:mt-5">
                    <label htmlFor="deviceImei"> IMEI </label>
                    <TextField
                      className="w-full bg-white"
                      id="deviceImei"
                      name="deviceImei"
                      value={values.deviceImei}
                      required
                      onFocus={handleFocus}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.deviceImei && touched.deviceImei}
                      helperText={
                        errors.deviceImei && touched.deviceImei
                          ? errors.deviceImei
                          : ""
                      }
                    />
                  </FormControl>

                  <FormControl className="w-full border-none lg:mt-5">
                    <label htmlFor="deviceSerialNo"> Serial Number </label>
                    <TextField
                      className="w-full bg-white"
                      id="deviceSerialNo"
                      name="deviceSerialNo"
                      value={values.deviceSerialNo}
                      required
                      onFocus={handleFocus}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.deviceSerialNo && touched.deviceSerialNo}
                      helperText={
                        errors.deviceSerialNo && touched.deviceSerialNo
                          ? errors.deviceSerialNo
                          : ""
                      }
                    />
                  </FormControl>
                </>
              ) : getTabById(activeTab).code === "Branch" ? (
                <>
                  <FormControl className="w-full border-none lg:mt-5">
                    <label htmlFor="branchName">Branch Name</label>
                    <TextField
                      className="w-full bg-white"
                      id="branchName"
                      name="branchName"
                      value={values?.branchName}
                      required
                      onFocus={handleFocus}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors?.branchName && touched?.branchName}
                      helperText={
                        errors?.branchName && touched?.branchName
                          ? errors?.branchName
                          : ""
                      }
                    />
                  </FormControl>

                  <FormControl className="w-full border-none lg:mt-5">
                    <label htmlFor="branchAddress"> Office Address </label>
                    <TextField
                      className="w-full bg-white"
                      id="branchAddress"
                      name="branchAddress"
                      value={values.branchAddress}
                      required
                      onFocus={handleFocus}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.branchAddress && touched.branchAddress}
                      helperText={
                        errors.branchAddress && touched.branchAddress
                          ? errors.branchAddress
                          : ""
                      }
                    />
                  </FormControl>

                  <FormControl className="w-full border-none lg:mt-5">
                    <label htmlFor="state"> State </label>
                    <SelectInput
                      {...{
                        className: "w-full bg-white",
                        value: values.state,
                        id: "state",
                        name: "state",
                        handleChange,
                        handleBlur,
                        required: false,
                        handleFocus,
                        loading: stateLoading,
                        error: errors.state && touched.state,
                        helperText: errors.state,
                        options: states,
                      }}
                    />
                  </FormControl>
                </>
              ) : getTabById(activeTab).code === "Department" ? (
                <>
                  <FormControl className="w-full border-none lg:mt-5">
                    <label htmlFor="department"> Department Name </label>
                    <TextField
                      className="w-full bg-white"
                      id="department"
                      name="department"
                      value={values.department}
                      required
                      onFocus={handleFocus}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.department && touched.department}
                      helperText={
                        errors.department && touched.department
                          ? errors.department
                          : ""
                      }
                    />
                  </FormControl>
                </>
              ) : (
                <FormControl className="w-full border-none lg:mt-5">
                  <label htmlFor="department"> Email Address </label>
                  <TextField
                    className="w-full bg-white"
                    id="email"
                    name="email"
                    value={values.email}
                    required
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email}
                    helperText={
                      errors.email && touched.email ? errors.email : ""
                    }
                  />
                </FormControl>
              )}
            </Box>

            <Box
              className={`flex ${
                getTabById(activeTab).code === "Department"
                  ? "justify-start"
                  : "justify-end"
              }`}
            >
              <Button
                onClick={(_) => navigate(-1)}
                className="my-10 w-1/2 lg:w-[120px] rounded-[10px] bg-[var(--c-primary-1)] hover:bg-[var(--c-danger-3)] text-[var(--c-primary-0)] hover:text-white self-end shadow-none normal-case mr-5"
                sx={{
                  ".MuiLoadingButton-loadingIndicatorCenter": {
                    color: "white",
                  },
                }}
                size="large"
                variant="contained"
                fullWidth
              >
                Cancel
              </Button>

              <LoadingButton
                className="my-10 w-1/2 lg:w-[120px] rounded-[10px] bg-[var(--c-primary-0)] hover:bg-[#FF5C5C]  self-end shadow-none normal-case"
                sx={{
                  ".MuiLoadingButton-loadingIndicatorCenter": {
                    color: "var(--c-bg-color) !important",
                  },
                }}
                size="large"
                type="submit"
                variant="contained"
                disabled={getStateData().loading}
                loading={getStateData().loading}
                fullWidth
              >
                {state.editable ? "Update" : "Submit"}
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </div>
    </HomeLayout>
  );
};

export default Create;
