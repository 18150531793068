import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { IBrand, ISplashImage } from "../utils/icons.utils";
import { useFormik } from "formik";
import { userLogin } from "../utils/validators";
import Alert from "../utils/alert";
import config from "../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { asyncLogin, getAuthData } from "../features/auth/authSlice";


const Login = () => {
const { loading, payload } = useSelector(getAuthData),
  dispatch = useDispatch();

  const formik = useFormik({
    initialValues: payload,
    validationSchema: userLogin,
    onSubmit: async (values) => {
       try{
        const res = await dispatch(asyncLogin(values)).unwrap();
        if(res.success ) window.location.href = config.routes.dashboard;

      }  catch (err) {
      console.error(err);
      Alert({type: 'error', message: 'Something went wrong'})
    }
  }
  })

  return (
    <div className="px-5 h-screen max-w-[1440px] mx-auto">
      <Box className="h-full">
        <Box className="lg:flex lg:justify-around h-ful">
          <div className="w-full">
            <img alt="brand" src={IBrand} className="ml-[13%] my-5" />
            <img
              src={ISplashImage}
              alt="IBankUser"
              className="m-aut rounded-[24px] lg:h-[600px] w-full h-[400px] mt-5 lg:w-[600px]"
            />
          </div>
          <div className="flex flex-col justify-center w-full">
          <div className="lg:bg-[#D70900] xl:p-[15%] px-5 mt-5 h-full flex flex-col md:justify-center rounded-r-[20px] w-full">
            <Box
              component="form"
              noValidate
              onSubmit={formik.handleSubmit}
              className="bg-white px-5 lg:px-[10%] py-8 md:px-4  md:rounded-[24px]">
              <h1 className="mb-5 text-[30px] font-bold">
                Login
              </h1>
              <Typography className="text-[400] text-[16px] text-[#52525B]">
              Log in to manage users.
              </Typography>
              <div className="mt-5">
                <label
                  htmlFor="userName"
                  className="block text-[16px] font-medium  text-[#090914]"
                >
                  Email Address
                </label>
                <TextField
                  value={formik.values.email}
                  type="text"
                  name="email"
                  id="email"
                  sx={{'.MuiInputBase-root': {borderRadius: '10px', width: '100%'}}}
                  className="block w-full border-0 py-2 text-gray-900 placeholder:text-gray-400 focus:ring-3 sm:text-sm sm:leading-6"
                  placeholder="johndoe@hotmail.com"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onFocus={formik.handleFocus}
                  error={formik.errors.email && formik.touched.email}
                  helperText={formik.errors.email && formik.touched.email ? formik.errors.email : ""}
                />
              </div>
              <div className="mt-5">
                <label
                  htmlFor="password"
                  className="block text-[16px] font-medium  text-[#090914]"
                >
                  Passcode
                </label>
                <TextField
                sx={{'.MuiInputBase-root': {borderRadius: '10px', width: '100%'}}}
                value={formik.values.password}
                  type="password"
                  name="password"
                  id="password"
                  className="block w-full border-0 py-2 text-gray-900 placeholder:text-gray-400 focus:ring-3  sm:text-sm sm:leading-6"
                  placeholder="*****"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onFocus={formik.handleFocus}
                  error={formik.errors.password && formik.touched.password}
                  helperText={formik.errors.password && formik.touched.password ? formik.errors.password : ""}
                />
              </div>
            
              <LoadingButton
              variant="text"
              {...{ loading, sx: {".MuiLoadingButton-loadingIndicatorCenter": {color: '#D70900 !important'}, textTransform: "capitalize", mt: '2.5rem', px: '2.5rem', bgcolor: '#F8FAFC', border: '1px solid rgba(233, 231, 231, 0.7)', borderRadius: '10px', py: '0.75rem', color: "#D70900", fontSize: '18px', '&:hover': {bgcolor: '#D70900', color: 'white'}} }}
                type="submit"
                className="rounded-[10px] md:py-3  font-semibold">
                Submit
              </LoadingButton>
            </Box>
          </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Login;
