import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";

export const getDashboard = createAsyncThunk('/dashboard/getDashboard', () => {
 try {
  return new BACKEND().send({
   type: 'get',
   to: `/dashboard`,
   useAlert: false
 })
 } catch (error) {
   console.error(error);
 }
})

export const getDashboardStats = createAsyncThunk('/dashboard/getDashboardStats', () => {
 try {
  return new BACKEND().send({
   type: 'get',
   to: `/dashboard/current`,
   useAlert: false
 })
 } catch (error) {
   console.error(error);
 }
})

const initialState = {
 loading: false,
 analytics: [],
 stats: []
};

export const dashboardSlice = createSlice({
 name: 'dashboard',
 initialState,
 reducers: {
 },
 extraReducers: (builder => {
  builder.addCase(getDashboard.pending, (state) => {
   state.loading = true;
  })
  .addCase(getDashboard.fulfilled, (state, {payload}) => {
   state.loading = false;
   if(payload.success) {
    state.analytics = payload.data;
   }
  })
  .addCase(getDashboard.rejected, state => {state.loading = false})
  
  builder.addCase(getDashboardStats.pending, (state) => {
   state.loading = true;
  })
  .addCase(getDashboardStats.fulfilled, (state, {payload}) => {
   state.loading = false;
   if(payload.success) {
    state.stats = payload?.data?.data
   }
  })
  .addCase(getDashboardStats.rejected, state => {state.loading = false})
  
 })
})

// export const { setPagination } = dashboardSlice.actions
export const getDashboardData = state => state.dashboard
export default dashboardSlice.reducer;