/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import HomeLayout from "../layouts/Home";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Table from "../components/table/Table";
import TablePreloader from "../components/Loader/TablePreloader";
import DropdownButton from "../components/DropdownButton";
import SearchBar from "../components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserData,
  getReports,
  setPagination,
} from "../features/users/userSlice";
import dayjs from "dayjs";
import {
  getDepartmentData,
  getDepartments,
} from "../features/settings/departmentSlice";
import DatePicker from "../components/others/DatePicker";
import { getMdaIdByName } from "../utils/helper";

export default function Report() {
  const { loading, report, pagination, downloadUrl } = useSelector(getUserData),
    { departments, loading: mdaLoading } = useSelector(getDepartmentData),
    dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPagination({ page: 1, search: "", filter: "", status: "" }));
    (async () => await dispatch(getDepartments()))();
  }, []);

  useEffect(() => {
    let cb = () => {};
    if (pagination.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout((_) => (async () => await dispatch(getReports()))(), 700);
    } else cb = (async () => await dispatch(getReports()))();

    return () => {
      clearTimeout(cb);
    };
  }, [
    pagination.search,
    pagination.page,
    pagination.filter,
    pagination.status,
  ]);

  const handleSearch = ({ target: { value } }) => {
    dispatch(
      setPagination({ ...pagination, search: value, filter: "", status: "" })
    );
  };

  const handleFilter = (value) => {
    dispatch(
      setPagination({ ...pagination, filter: value, search: "", status: "" })
    );
  };
  const handleStatus = (value) => {
    dispatch(
      setPagination({ ...pagination, filter: "", search: "", status: value })
    );
  };

  const getStatus = (user) => (user?.isEarly ? "Early" : "Late");

  const data = report.map((user, idx) => ({
    "S/N": pagination.pageSize * (pagination.page - 1) + idx + 1,
    name: user?.User?.firstName + " " + user?.User?.lastName,
    Department: user?.User?.Department?.department || "N/A",
    "Staff ID": user?.User?.staffId || "N/A",
    "Clock-In": dayjs(user.clockInTime).format("hh:mm:ss A") || "N/A",
    "Clock-Out": dayjs(user.clockOutTime).format("hh:mm:ss A") || "N/A",
    Date: dayjs(user.createdAt).format("MMM DD, YYYY") || "N/A",
    Status: getStatus(user),
  }));

  const options = departments?.map((department) => ({
    value: department?.department,
    id: department?.id,
  }));

  const statusOptions = [
    {
      id: 1,
      value: "Early",
    },
    {
      id: 2,
      value: "Late",
    },
  ];

  const handleDateFilter = (date) => {
    dispatch(
      setPagination({ startDate: date?.startDate, endDate: date?.endDate })
    );
  };

  return (
    <HomeLayout>
      <div className="mb-10">
        <Typography
          variant="h6"
          noWrap
          component="div"
          color="#040316"
          fontSize="32px"
          className="sofiaProBold"
          sx={{ my: 3 }}
        >
          Report
        </Typography>

        <div className="lg:grid lg:grid-cols-3 mb-8 items-center">
          <div className="col-span-2 lg:mb-0 mb-6">
            <SearchBar
              {...{
                onChange: handleSearch,
                placeholder: "Search by name or staff id...",
              }}
            />
          </div>
          <div className="grid xl:grid-cols-3 grid-cols-2 gap-4 col-span-1 flex-wrap">
            <DropdownButton
              title={"Department"}
              loading={mdaLoading}
              value={pagination.filter}
              onChange={handleFilter}
              wrapperClass="bg-[#D70900] text-white"
              options={options}
            />
            <DropdownButton
              value={pagination.status}
              onChange={handleStatus}
              title={"Status"}
              wrapperClass="bg-[var(--c-primary-1)] text-[var(--c-primary-0)]"
              options={statusOptions}
            />
            <Button
              variant="contained"
              color="error"
              LinkComponent="a"
              href={`${downloadUrl}clock/downloadreports/?pageNumber=${
                pagination.page
              }&pageSize=${pagination.pageSize}&departmentId=${
                getMdaIdByName(pagination.filter, departments) || ""
              }&search=${pagination.search || ""}&isEarly=${
                pagination.status
              }&startDate=${pagination.startDate}&endDate=${
                pagination.endDate
              }`}
              className="rounded-[10px]"
            >
              Download
            </Button>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-2">
          {/* <div></div> */}
          <DatePicker {...{ handleDateFilter }} />
        </div>

        <Box className="">
          <div className="w-full">
            <Container
              className={`${
                (data.length > 0 || loading) &&
                "mt-8 py-5 bg-[var(--c-bg-color)]"
              } rounded-lg lg:min-w-full`}
              sx={{ px: 0 }}
            >
              {data.length > 0 && (
                <Box className="flex justify-between mb-2">
                  <Typography fontFamily={"Lota"}>All Reports</Typography>
                </Box>
              )}

              {loading && report.length === 0 ? (
                <TablePreloader />
              ) : (
                <Table
                  {...{
                    data,
                    setPagination: (d) => dispatch(setPagination(d)),
                    pagination: { ...pagination, hidden: false },
                    isLoading: loading,
                    tableMsg: [
                      "No report in the system,",
                      "Kindly check back later.",
                    ],
                  }}
                />
              )}
            </Container>
          </div>
        </Box>
      </div>
    </HomeLayout>
  );
}
